<template>
  <transition name="fade">
    <div class="statbox-container active" v-if="show > 1">
      <div class="statbox-container-inner active">
        <p class="p-title" v-html="panel.answer" />
        <p class="p-movie" v-html="panel.movie" />
        <div class="emoji-holder">
          <div
            class="emoji-container"
            :class="{
              win: this.score < 5 && show != 3,
              wrong: index < this.score - 1,
              correct: index == this.score - 1,
            }"
            v-for="(emoji, index) in current.emojis"
            :key="index + '-' + current.id"
            :style="{ animationDelay: 0.5 + index * 0.05 + 's' }"
          >
            <EmojiBg />
            <div class="emoji active" v-html="emoji.code" />
          </div>
        </div>
        <p class="share-line">
          <span class="p-share">#Filmoji{{ current.id }}</span>
        </p>
        <button class="share-button" @click="CopyShareMessage">SHARE</button>
        <div class="copy-message" :class="{ active: copySuccessful }">
          COPIED
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import EmojiBg from "@/components/EmojiBg.vue";
import { commontools } from "@/mixins/Common";

export default {
  name: "StatBox",
  mixins: [commontools],
  props: {
    show: Number,
    score: Number,
    current: Object,
  },
  components: {
    EmojiBg,
  },
  data() {
    return {
      panel: {
        phase: 0,
        answer: "ANSWER:",
        movie: "",
      },
      copySuccessful: false,
      message: {
        title: "",
        share: "",
        emojis: "",
        day: "",
        month: "",
      },
    };
  },
  methods: {
    GetResultStats(_results) {
      let results = _results;

      this.message.day = this.current.day;
      this.message.month = this.current.month;
      this.message.emojis = results.share[this.score - 1].message;
      this.message.share =
        "#Filmoji" +
        this.current.id +
        "\n\n" +
        this.message.emojis +
        "\n\nhttps://filmoji.co.uk/";
      this.message.title =
        results.resultarray[this.score - 1][
          Math.floor(Math.random() * results.resultarray[this.score - 1].length)
        ].message;

      this.panel.movie = this.current.title;
      this.UpdatePanel();
    },
    UpdatePanel() {
      this.panel.phase++;
      setTimeout(() => {
        this.panel.phase++;
      }, 100);

      setTimeout(() => {
        if (this.panel.phase == 2) this.UpdatePanel();
      }, 3000);
    },
    CloseStats() {
      this.panel.phase = 0;
      this.$emit("close-box", 0);
    },
    CopyShareMessage() {
      if (this.copySuccessful) return;

      let text = this.message.share;

      const textArea = document.createElement("textarea");
      let range = document.createRange();
      textArea.contentEditable = true;
      textArea.readOnly = false;
      range.selectNodeContents(textArea);
      textArea.style.position = "fixed";
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = 0;
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";
      textArea.value = text;

      document.body.appendChild(textArea);

      textArea.select();
      textArea.setSelectionRange(0, 99999); /* For mobile devices */

      try {
        const successful = document.execCommand("copy");
        this.copySuccessful = true;
      } catch (err) {
        console.log("Oops, unable to copy");
      }

      document.body.removeChild(textArea);

      setTimeout(() => {
        this.copySuccessful = false;
      }, 1000);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.statbox-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fcc100;
  box-sizing: border-box;
  z-index: 10;
}
.statbox-container-inner {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  opacity: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.statbox-container-inner.active {
  opacity: 1 !important;
}
.share-button {
  background-color: #ec008c;
  padding: 6px 20px;
  margin: 5px 0 8px;
  border-radius: 2px;
  color: #000;
  font: inherit;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #000;
  box-shadow: inset -3px 3px rgba(black, 0.2);
  letter-spacing: inherit;
  transition: background-color 0.3s;
  will-change: background-color;

  &:hover {
    background-color: lighten(#ec008c, 10%);
  }
}
.copy-message {
  position: absolute;
  padding: 5px 10px;
  background-color: #00ba4a;
  color: #000;
  display: inline-block;
  font-weight: bold;
  left: 50%;
  z-index: -1;
  transform: translate3d(-50%, -100%, 0);
  -webkit-transform: translate3d(-50%, -100%, 0);
  opacity: 0;
  border-radius: 2px;
  border: 2px solid #000;
  font-size: 13px;
  box-shadow: inset -2px 2px #008433;
}
.share-line {
  font-size: 18px;
  line-height: 22px;
}
.copy-message.active {
  z-index: 10 !important;
  animation-name: copied;
  animation-duration: 1s;
  animation-iteration-count: 1;
}
@keyframes copied {
  0% {
    opacity: 0;
    transform: translate3d(-50%, -100%, 0);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: translate3d(-50%, -250%, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-50%, -270%, 0);
  }
}
@-webkit-keyframes copied {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -100%, 0);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, -250%, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -270%, 0);
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
