<template>
  <svg
    class="emoji-container__bg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 270 345"
  >
    <rect
      class="cls-7"
      x="12.04"
      y="25.22"
      width="225.85"
      height="308.08"
      rx="20"
      ry="20"
    />
    <polygon
      class="cls-3"
      points="47.73 309.63 200.13 309.63 197.47 333.49 27.05 332.83 47.73 309.63"
    />
    <rect
      class="cls-5"
      x="12.04"
      y="25.77"
      width="225.85"
      height="308.08"
      rx="20"
      ry="20"
    />
    <path
      class="cls-4"
      d="M200.13,333.2s26.21,1.62,40.82-7.55c19.81-12.43,16.28-29.09,16.28-29.09l-57.1,36.64Z"
    />
    <path
      class="cls-3"
      d="M190.8,331.9s23.38,.68,31.05-1.87c7.67-2.55,26.07-23.63,26.07-23.63l-30.47-14.45-23.77,20.01-2.87,19.93"
    />
    <rect
      class="cls-6"
      x="30.43"
      y="10.49"
      width="225.85"
      height="308.08"
      rx="20"
      ry="20"
    />
    <rect
      class="cls-2"
      x="30.43"
      y="10.49"
      width="225.85"
      height="308.08"
      rx="20"
      ry="20"
    />
    <rect
      class="cls-1"
      x="30.43"
      y="18.54"
      width="214.26"
      height="300.03"
      rx="20"
      ry="20"
    />
  </svg>
</template>

<style lang="scss">
.emoji-container {
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    z-index: 0;
    pointer-events: none;

    .cls-1 {
      fill: #eae6df;
      transition: fill 0.3s;
    }
    .cls-2 {
      fill: #b2afa3;
      transition: fill 0.3s;
    }
    .cls-3,
    .cls-4 {
      fill: #6b4724;
    }
    .cls-5 {
      fill: none;
    }
    .cls-5,
    .cls-6,
    .cls-4,
    .cls-7 {
      stroke: #000;
    }
    .cls-5,
    .cls-4,
    .cls-7 {
      stroke-width: 4px;
    }
    .cls-6 {
      fill: #b2aea1;
      stroke-width: 8px;
    }
    .cls-7 {
      fill: #a06d47;
    }
  }

  &.wrong {
    .cls-1 {
      fill: #7f7d76;
    }

    .cls-2 {
      fill: #686763;
    }
  }

  &.correct {
    .cls-1 {
      fill: #00b94a;
    }

    .cls-2 {
      fill: #008333;
    }
  }
}
</style>

<script>
export default {
  name: "EmojiBg",
};
</script>
