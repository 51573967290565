<template>
  <div :class="{ active: show > 1 }" class="tutorial-container">
    <button
      class="close-button close-button-tut fade-button"
      @click="ClosePanel"
    ></button>
    <div class="tutorial-container-inner">
      <p class="p-title">HOW TO PLAY</p>
      <p v-html="tutorialData.desc"></p>

      <div class="emoji-holder emoji-holder-tutorial">
        <div
          class="emoji-container"
          :class="{
            wrong:
              (index <= tut.counter && index < 3 && tut.checkAnswer) ||
              (index < tut.counter && index < 3),
            correct: tut.isCorrect && tut.counter == index && index == 3,
          }"
          v-for="(emoji, index) in tutorialData.emojis"
          :key="index"
        >
          <EmojiBg />
          <div
            :class="{
              active: tut.counter >= index,
              correct: tut.isCorrect && tut.checkAnswer,
            }"
            class="emoji"
            v-html="emoji['code']"
          ></div>
        </div>
      </div>

      <div
        class="tut-input"
        :class="{
          shake: tut.checkAnswer && !tut.isCorrect,
          correct: tut.checkAnswer && tut.isCorrect,
        }"
      >
        <div
          class="tut-input-inner"
          v-html="tut.answer"
          :class="{
            shake: tut.checkAnswer && !tut.isCorrect,
            correct: tut.checkAnswer && tut.isCorrect,
          }"
        ></div>
        <div class="tut-button"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { commontools } from "@/mixins/Common";
import EmojiBg from "@/components/EmojiBg.vue";

export default {
  name: "TutorialBox",
  mixins: [commontools],
  props: {
    show: Number,
  },
  components: {
    EmojiBg,
  },
  data() {
    return {
      tutorialData: {
        desc: "",
        answer: [],
        emojis: [],
      },
      tut: {
        answer: "",
        charcounter: 0,
        checkAnswer: false,
        isOpen: false,
        isCorrect: false,
        counter: -1,
      },
    };
  },
  methods: {
    ClosePanel() {
      this.$emit("close-box", 1);
      this.tut.isOpen = false;
      this.tut.answer = "";
      this.tut.charcounter = 0;
      this.tut.checkAnswer = false;
      this.tut.counter = -1;
    },
    StartTutorial() {
      this.tut.isOpen = true;
      setTimeout(() => {
        this.ShowEmoji();
      }, 1000);
    },
    SendData(_data) {
      this.tutorialData = _data;
    },
    ShowEmoji() {
      this.tut.counter = this.tut.counter >= 3 ? 0 : this.tut.counter + 1;
      setTimeout(() => {
        this.TypeAnswer();
      }, 1000);
    },
    ResetEmojis() {
      this.tut.counter = -1;
      setTimeout(() => {
        this.ShowEmoji();
      }, 1000);
    },
    TypeAnswer() {
      if (!this.tut.isOpen) return;

      this.tut.answer +=
        this.tutorialData.answer[this.tut.counter][this.tut.charcounter];
      this.tut.charcounter++;
      setTimeout(() => {
        if (!this.tut.isOpen) return;
        let isComplete =
          this.tut.charcounter >=
          this.tutorialData.answer[this.tut.counter].length
            ? this.CheckAnswer()
            : this.TypeAnswer();
      }, 80);
    },
    CheckAnswer() {
      if (!this.tut.isOpen) return;
      this.tut.isCorrect = this.tut.counter == 3 ? true : false;
      this.tut.checkAnswer =
        this.tut.counter > -1 && this.tut.counter < 4 ? true : false;

      setTimeout(() => {
        if (!this.tut.isOpen) return;
        this.tut.answer = "";
        this.tut.charcounter = 0;

        this.tut.checkAnswer = false;

        if (this.tut.counter == 3) {
          this.ResetEmojis();
        } else {
          this.ShowEmoji();
        }
      }, 2500);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.tutorial-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, 100vh, 0px);
  -webkit-transform: translate3d(-50%, 100vh, 0px);
  opacity: 0;
  width: 300px;
  height: 405px;
  background-image: url(@/assets/panel2.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  box-sizing: border-box;
  z-index: 10;
  transition: 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  -webkit-transition: 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.tutorial-container.active {
  opacity: 1;
  transform: translate3d(-50%, -50%, 0px);
  -webkit-transform: translate3d(-50%, -50%, 0px);
}
.tutorial-container-inner {
  position: absolute;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  box-sizing: border-box;
  padding: 50px 0 20px;
}
.tutorial-container-inner p {
  color: #fff;
}

.tut-input {
  font-family: "Roboto Mono", monospace;
  left: 0;
  width: 224px;
  height: 46px;
  line-height: 41px;
  position: relative;
  border: none;
  padding: 0;
  box-sizing: border-box;
  margin: 20px auto 10px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url(@/assets/input-field.png);
}
.tut-button {
  right: 1px;
  position: absolute;
  cursor: pointer;
  background-color: #ec008c;
  border-left: 1px solid #000;
  width: 38px;
  height: 41px;
  border-radius: 0 7px 7px 0;
  color: #fff;
  font-weight: bold;
  line-height: 46px;
  top: 1px;
  z-index: 1;
  background-position: center;
  background-size: 80% auto;
  background-repeat: no-repeat;
  background-image: url(@/assets/enter-icon.png);
}

.tut-input-inner {
  position: absolute;
  width: 200px;
  height: 41px;
  top: 1px;
  left: 4px;
  text-align: left;
  text-indent: 10px;
  border-radius: 7px;
}
.tut-input.shake {
  animation-name: shake;
  animation-duration: 0.1s;
  animation-iteration-count: 10;
}
.tut-input-inner.shake {
  color: #fff;
  background-color: #ea0038;
}
.tut-input-inner.correct {
  color: #fff;
  background-color: #00ba4a;
}
</style>
