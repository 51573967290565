<template>
  <div class="wrapper" :class="{ active: isReady }">
    <div v-if="currentMovie != null" class="game-container">
      <div class="game-message">
        <div class="message-container">
          <p class="p-title">👀 Emojis = Moments 🎥</p>
          <p>
            Movie lover? Prove it. <br />Name the film in as few clues as
            possible.
          </p>
        </div>

        <div class="emoji-holder">
          <div
            class="emoji-container"
            v-for="(emoji, index) in currentMovie.emojis"
            :key="index"
            :class="{
              wrong: index < counter.current,
              correct:
                index == counter.current - 1 &&
                currentMovie.emojis[counter.current + 1]?.show,
            }"
            :style="{ animationDelay: index * 0.05 + 's' }"
          >
            <EmojiBg />
            <div
              :class="{ active: emoji['show'] }"
              class="emoji"
              v-html="emojilist[index]"
            ></div>
          </div>
        </div>

        <div class="input-wrapper">
          <div
            v-if="input.status == 1"
            class="input-holder"
            :class="{ shake: input.shake }"
          >
            <input
              v-model="input.answer"
              placeholder="Enter film"
              v-on:keydown.enter="SubmitAnswer"
            />
            <div @click="SubmitAnswer" class="input-button fade-button"></div>
          </div>
        </div>

        <StatBox
          ref="statBox"
          :show="input.showStats"
          :score="counter.current"
          :current="currentMovie"
          @close-box="ClosePanel"
        />
      </div>

      <div class="previous-section">
        <div class="previous-section-bg"></div>
        <p>Past Games</p>
        <div class="previous-carousel">
          <div
            class="previous-carousel-inner"
            :style="{
              right: history.rightposition + 'px',
              width: history.tileWidth * movies.length + 'px',
            }"
          >
            <div
              class="carousel-entry fade-button"
              v-for="(movie, index) in movies"
              :key="index"
              @click="ChangeGame(index, movie.title)"
              :class="{ active: currentMovie.id - 1 == index }"
            >
              <div
                class="carousel-entry-inner"
                :class="{ active: currentMovie.title == movie.title }"
              >
                <span class="prev-month">{{ movie.month }}</span>
                <span class="prev-day p-title"> {{ movie.day }}</span>
              </div>
            </div>
          </div>
        </div>
        <button
          v-if="history.diff > -(this.movies.length - 5)"
          class="history-button history-button-back fade-button"
          @click="ScrollFilmojis(-1)"
        ></button>
        <button
          v-if="history.diff < 0"
          class="history-button history-button-forward fade-button"
          @click="ScrollFilmojis(1)"
        ></button>
      </div>
    </div>

    <div
      class="overlay-background"
      :class="{
        display: input.showTutorial >= 1,
        show: input.showTutorial >= 2,
        tutorial: input.showTutorial > 0,
      }"
    />

    <TutorialBox
      ref="tutorialBox"
      :show="input.showTutorial"
      @close-box="ClosePanel"
    />
  </div>
  <ErrorBox :show="input.showError" />
  <div id="preload">
    <img :src="ReturnImagePath('results.png', '')" />
    <img :src="ReturnImagePath('input-field.png', '')" />
    <img :src="ReturnImagePath('panel.png', '')" />
  </div>
</template>

<script>
import StatBox from "@/components/StatBox.vue";
import TutorialBox from "@/components/TutorialBox.vue";
import ErrorBox from "@/components/ErrorBox.vue";
import EmojiBg from "@/components/EmojiBg.vue";
import { commontools } from "@/mixins/Common";

export default {
  name: "MainView",
  mixins: [commontools],
  data() {
    return {
      isReady: false,
      delay: 500,
      history: {
        canClick: false,
        max: 0,
        tileWidth: 52,
        diff: 0,
        rightposition: 0,
      },
      movies: {},
      tutorial: {},
      results: {},
      currentMovie: null,
      counter: {
        id: 0,
        current: 0,
        max: 4,
      },
      emojilist: ["", "", "", ""],
      input: {
        status: 0,
        reveal: "",
        answer: "",
        showStats: 1,
        showTutorial: 1,
        showError: 1,
        shake: false,
      },
      currentpanel: -1,
    };
  },
  methods: {
    ShowEmoji(_counter) {
      let counter = _counter;
      this.emojilist[counter] = this.currentMovie.emojis[counter].code;
      this.currentMovie.emojis[counter].show = true;
    },
    SubmitAnswer() {
      if (this.input.status > 1 || this.input.shake) return;

      this.counter.current++;

      if (this.CheckAnswers()) {
        this.CorrectAnswer();
        this.input.answer = "";
      } else {
        this.input.shake = true;

        setTimeout(() => {
          this.input.shake = false;
          this.input.answer = "";

          if (this.counter.current == this.counter.max) {
            this.counter.current++;
            this.GameOver();
          } else {
            this.ShowEmoji(this.counter.current);
          }

          this.SaveGamePosition(this.counter.id);
        }, this.delay);
      }
    },
    CheckAnswers() {
      let ans = this.input.answer.toLowerCase().replace(/ /g, "");
      const found = this.currentMovie.accepted.find(
        (element) => element.toLowerCase().replace(/ /g, "") == ans
      );
      return found == undefined ? false : true;
    },
    CorrectAnswer() {
      this.ShowEmojis(3);
      setTimeout(() => {
        this.input.status = 3;
      }, this.delay * 3);

      this.SaveScore(this.currentMovie.id, this.counter.current);
      this.ShowStats(3);
      this.input.reveal = this.currentMovie.title;
    },
    GameOver() {
      setTimeout(() => {
        this.input.status = 2;
      }, this.delay * 3);

      this.SaveScore(this.currentMovie.id, this.counter.current);
      this.ShowStats(2);
      this.input.reveal = this.currentMovie.title;
    },
    ShowEmojis(_count) {
      [...Array(_count + 1)].forEach((_, i) => this.ShowEmoji(i));
    },
    ShowPanel(_val) {
      if (this.currentpanel == _val) return;

      this.currentpanel = _val;
      if (this.currentpanel == 0) {
        this.ShowStatsPanel();
        this.CloseTutorialPanel();
      } else {
        this.ShowTutorialPanel();
        this.CloseStatsPanel();
      }
    },
    ShowStats() {
      setTimeout(() => {
        this.ShowStatsPanel();
      }, this.delay);
    },
    ShowStatsPanel() {
      setTimeout(() => {
        this.$refs.statBox.GetResultStats(this.results);
      }, 100);

      this.input.showStats = 1;

      setTimeout(() => {
        this.input.showStats = 2;
      }, 150);
    },
    ClosePanel(_val) {
      this.currentpanel = -1;
      let oucome =
        _val == 0 ? this.CloseStatsPanel() : this.CloseTutorialPanel();
    },
    CloseStatsPanel() {
      this.input.showStats = 1;
      setTimeout(() => {
        this.input.showStats = 0;
      }, 300);
    },
    ShowTutorialPanel() {
      this.input.showTutorial = 1;
      setTimeout(() => {
        this.$refs.tutorialBox.StartTutorial();
        this.input.showTutorial = 2;
      }, 150);
    },
    CloseTutorialPanel() {
      this.input.showTutorial = 1;
      setTimeout(() => {
        this.input.showTutorial = 0;
      }, 300);
    },
    ScrollFilmojis(_diff) {
      this.history.diff += _diff * 5;

      if (this.history.diff > 0) this.history.diff = 0;
      if (this.history.diff < -(this.movies.length - 5))
        this.history.diff = -(this.movies.length - 5);
      this.history.rightposition = this.history.diff * this.history.tileWidth;
    },
    ChangeGame(_diff, _title) {
      if (!this.history.canClick || _title == this.currentMovie.title) return;
      this.history.canClick = false;

      this.currentpanel = -1;
      this.input.showStats = 0;
      this.input.showTutorial = 0;

      this.SetupGame(_diff);
    },
    InActivateEmojis(_emojis) {
      _emojis.forEach((item) => (item.show = false));
    },
    SetupGame(_offset) {
      let offset = _offset;

      this.counter.current = 0;
      this.input.reveal = "";
      this.input.answer = "";
      this.input.status = 0;
      this.input.showStats = 0;
      this.input.showTutorial = 0;
      this.input.shake = false;

      // get the current game using id, if it doesnt exist create / return
      let game = this.CreateGame(offset);
      this.counter.id = game.id;

      this.currentMovie = this.movies[offset];
      this.InActivateEmojis(this.currentMovie.emojis);

      if (game.complete) {
        this.input.showStats = 3;
        this.input.status = game.score < 5 ? 3 : 2;
        this.counter.current = game.score;
        this.ShowEmojis(3);
        this.input.reveal = this.currentMovie.title;
        this.$nextTick(() => {
          this.$refs.statBox.GetResultStats(this.results);
        });
      }

      // if game is complete show all emojis and stats, if not sshow what stage we are up to
      setTimeout(() => {
        if (!game.complete) {
          this.input.status = 1;
          this.counter.current = game.counter;
          this.ShowEmojis(this.counter.current);
        }
        this.history.canClick = true;
      }, 1000);
    },
  },
  mounted() {
    this.GetData()
      .then((_data) => {
        this.movies = _data.movies.reverse();
        this.results = _data.results;

        this.$refs.tutorialBox.SendData(_data.tutorial);

        this.history.max = this.movies.length - 1;
        this.history.rightposition =
          this.history.max + 1 < 5
            ? ((5 - (this.history.max + 1)) * this.history.tileWidth) / 2
            : 0;

        // if local storage doesnt exist create new obj
        if (!this.StorageExists()) {
          this.CreateGameArray();
        }

        setTimeout(() => {
          this.input.showStats = 0;
          this.isReady = true;
          this.SetupGame(this.history.max);
          this.ShowTutorialPanel();
        }, 300);
      })
      .catch((error) => {
        this.input.showError = 2;
      });
  },
  components: {
    StatBox,
    TutorialBox,
    ErrorBox,
    EmojiBg,
  },
};
</script>

<style lang="scss">
#preload {
  display: none;
}
.wrapper {
  opacity: 0;
  transition: 0.2s;
}
.wrapper.active {
  opacity: 1 !important;
}
.game-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  max-width: 96%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
}
.message-container {
  margin-bottom: 40px;
}
.message-container h2 {
  margin: 0;
  font-size: 22px;
}
.movie-reveal {
  min-height: 40px;
  position: relative;
  width: 100%;
  margin: 20px 0 0;
}
.movie-reveal-title {
  position: relative;
  width: 100%;
  font-size: 22px;
  text-transform: uppercase;
  line-height: 26px;
}
.input-wrapper {
  margin: 30px 0 0;
  min-height: 70px;
}
.input-holder {
  margin: 0 auto;
  width: 296px;
  height: 61px;
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url(@/assets/input-field.png);
  box-sizing: border-box;
}
.input-button {
  right: 1px;
  position: absolute;
  cursor: pointer;
  background-color: #ec008c;
  border-left: 1px solid #000;
  width: 50px;
  height: 54px;
  border-radius: 0 9px 9px 0;
  color: #fff;
  font-weight: bold;
  line-height: 46px;
  top: 1px;
  z-index: 1;
  background-position: center;
  background-size: 80% auto;
  background-repeat: no-repeat;
  background-image: url(@/assets/enter-icon.png);
  box-shadow: inset -3px 2px #d10588;
}
.input-button.inactive:hover,
.input-button.inactive {
  background-color: #3d315b;
  opacity: 0.5 !important;
}
.input-button.inactive:hover {
  opacity: 1;
}
.correctInput {
  background-color: #44b758 !important;
}
input {
  font-family: "Roboto Mono", monospace;
  left: 5px;
  top: 1px;
  width: 239px;
  height: 54px;
  line-height: 50px;
  position: absolute;
  font-size: 1.2rem;
  text-indent: 14px;
  border: none;
  background-color: transparent;
  border-radius: 9px 0 0 9px;
  padding: 0;
  z-index: 1;
}
.input-holder.shake {
  animation-name: shake;
  animation-duration: 0.1s;
  animation-iteration-count: 10;
}
.input-holder.shake .input-button {
  transition: 0s !important;
  opacity: 1 !important;
}
.input-holder.shake input {
  color: #fff !important;
  background-color: #ea0038;
}
@keyframes shake {
  0% {
    transform: translate3d(0, 0, 0);
  }
  33% {
    transform: translate3d(-5px, 0, 0);
  }
  66% {
    transform: translate3d(5px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
  }
  33% {
    -webkit-transform: translate3d(-5px, 0, 0);
  }
  66% {
    -webkit-transform: translate3d(5px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
  }
}
.answerbox p {
  font-size: 24px;
  line-height: 30px;
  margin: 0;
}

.emoji-holder {
  margin: 0 auto;
  min-height: 70px;
  width: auto;
  text-align: center;
}
.emoji-holder-tutorial {
  margin: 30px 0 10px;
}
.emoji-container {
  width: 70px;
  height: 92px;
  display: inline-block;
  margin: 0 2px;
  position: relative;
  vertical-align: top;
}
.emoji-container.win {
  animation-name: winner;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  animation-iteration-count: 3;
}
@keyframes winner {
  0% {
    transform: translate3d(0%, 0%, 0);
    animation-timing-function: ease-in;
  }
  30% {
    transform: translate3d(0%, 10%, 0);
    animation-timing-function: ease-in-out;
  }
  70% {
    transform: translate3d(0%, -10%, 0);
    animation-timing-function: ease-in-out;
  }
  0% {
    transform: translate3d(0%, 0%, 0);
    animation-timing-function: ease-in;
  }
}

.emoji-box {
  border-radius: 12px;
  text-align: center;
}
.emoji {
  width: 60px;
  height: 60px;
  display: block;
  visibility: hidden;
  margin: 18px 0 0 7px;
  font-size: 40px;
  transform: scale3d(0, 0, 0);
  -webkit-transform: scale3d(0, 0, 0);
}
.emoji.active {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  visibility: visible;
  transform: scale3d(1, 1, 1);
  -webkit-transform: scale3d(1, 1, 1);
}
.tutorial-container-inner .emoji-container,
.tutorial-container-inner .emoji,
.tutorial-container-inner .emoji-box {
  width: 52px;
  height: 68px;
  font-size: 32px;
}
.tutorial-container-inner .emoji {
  margin: 14px 0 0 3px;
  width: 48px;
  height: 48px;
}
.previous-section {
  margin-top: 80px;
  position: relative;
  color: #fff;
}
.previous-section p {
  margin: 30px 0 20px;
  color: #fff;
}
.previous-carousel {
  width: 260px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  height: 70px;
}
.previous-carousel-inner {
  position: absolute;
  width: auto;
  right: 0;
  transition: 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  -webkit-transition: 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}
.carousel-entry {
  position: relative;
  width: 50px;
  height: 69px;
  border-radius: 6px;
  vertical-align: text-top;
  margin: 0 1px;
  background-image: url(@/assets/date.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: inline-block;
  text-align: center;
}
.carousel-entry.active::after {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: 3px;
  left: 4px;
  width: 45px;
  height: 8px;
  background-color: #fcc000;
  opacity: 1;
  border-radius: 0 0 4px 4px;
}
.carousel-entry-inner {
  padding-left: 2px;
}
.prev-day {
  font-size: 20px;
  margin: 0;
  color: #ec008c;
}
.prev-month {
  display: block;
  margin: 22px 0 0;
  font-size: 12px;
  color: #000;
  line-height: 7px;
}
.previous-section-bg {
  position: absolute;
  width: 300vw;
  height: 50vh;
  top: -40px;
  background-color: #ec008c;
  left: -100vw;
  z-index: -1;
}
.history-button {
  position: absolute;
  top: 48px;
  border: none;
  padding: 0;
  width: 25px;
  height: 32px;
  background-color: transparent;
  color: #000;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
  background-image: url(@/assets/chevron.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.history-button-back {
  left: -20px;
  transform: scale(-1, 1);
}
.history-button-forward {
  right: -20px;
}
.previous-section p {
  margin-bottom: 10px;
}

.overlay-background {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-repeat: repeat;
  transition: background-color 0.3s, opacity 0.3s;
  -webkit-transition: background-color 0.3s, opacity 0.3s;
  opacity: 0;
}
.overlay-background.tutorial {
  background-color: rgba(0, 0, 0, 0.5);
}
.overlay-background.display {
  display: block;
}
.overlay-background.show {
  opacity: 1;
}

.game-message {
  position: relative;
}

@media screen and (max-width: 375px) {
  .game-container {
    top: 110px;
    transform: translate3d(-50%, 0%, 0);
    -webkit-transform: translate3d(-50%, 0%, 0);
  }
  .message-container {
    margin-bottom: 20px;
  }
  .input-wrapper {
    margin: 10px 0;
  }
  .previous-section {
    margin-top: 40px;
  }
  .previous-section-bg {
    top: -20px;
  }
}
</style>
