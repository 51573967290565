import axios from "axios";
import Vue from "vue";
export const commontools = {
  data() {
    return {
      array: "filmoji_data",
    };
  },
  methods: {
    async GetData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_PHP}`);
        return response.data;
      } catch (error) {
        console.log("Error getting server data!");
      }
    },
    ReturnImagePath(_img, _path) {
      return require("@/assets/" + _path + _img);
    },
    StorageExists() {
      if (
        localStorage.getItem(this.array) == null ||
        localStorage.getItem(this.array) == undefined
      )
        return false;
      return true;
    },
    CreateGameArray() {
      let gamearray = [];
      localStorage.setItem(this.array, JSON.stringify(gamearray));
    },
    SaveGamePosition(_id) {
      let gamearray = JSON.parse(window.localStorage.getItem(this.array));
      let id = _id;
      for (let i = 0; i < gamearray.length; i++) {
        if (gamearray[i].id == id) {
          gamearray[i].counter++;
          break;
        }
      }
      window.localStorage.setItem(this.array, JSON.stringify(gamearray));
    },
    CreateGame(_id) {
      let gamearray = JSON.parse(window.localStorage.getItem(this.array)),
        id = _id;
      let game = gamearray.find((obj) => obj.id == id);

      if (game == undefined) {
        let obj = {
          complete: false,
          id: id,
          score: 0,
          counter: 0,
        };

        gamearray.push(obj);
        window.localStorage.setItem(this.array, JSON.stringify(gamearray));

        return obj;
      } else {
        return game;
      }
    },
    SaveScore(_id, _score) {
      let gamearray = JSON.parse(window.localStorage.getItem(this.array)),
        id = _id - 1;
      let game = gamearray.find((obj) => obj.id == id);
      game.score = _score;
      game.complete = true;
      window.localStorage.setItem(this.array, JSON.stringify(gamearray));
    },
    GetStats() {
      let arr = JSON.parse(localStorage.getItem(this.array)),
        newarr = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].complete) newarr.push(arr[i]);
      }
      return newarr;
    },
    // GetDate () {
    //   const d = new Date();
    //   return d.getDate().toString() + d.getMonth().toString() + d.getFullYear().toString();

    // },
    // SetDaysDiff () {
    //   const d = new Date();
    //   let date1 = new Date(this.datestart);
    //   let date2 = new Date(d.getMonth()+1 + "/" + d.getDate() + "/" + d.getFullYear());
    //   let Difference_In_Time = date2.getTime() - date1.getTime();
    //   let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    //   this.diff = Difference_In_Days;

    //   return this.diff
    // },
    // GetDaysSince (_offset) {
    //   const d = new Date(), offset = _offset - 1;
    //   let dateOffset = (24*60*60*1000) * offset; //5 days
    //   let date1 = new Date(this.datestart);
    //   let d2 = new Date();
    //   d2.setTime(d2.getTime() - dateOffset);
    //   let date2 = new Date(d2.getMonth()+1 + "/" + d2.getDate() + "/" + d2.getFullYear());
    //   // To calculate the time difference of two dates
    //   let Difference_In_Time = date2.getTime() - date1.getTime();
    //   // To calculate the no. of days between two dates
    //   return Difference_In_Time / (1000 * 3600 * 24);
    // },
    // GetMovieDate (_diff) {

    //   const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    //   let d = new Date(this.datestart);
    //   d.setDate( d.getDate() + _diff);
    //   return [ d.getDate(), monthNames[d.getMonth()] ];
    // }
  },
  mounted: function () {},
};
