<template>
  <div :class="{ active: show > 1 }" class="error-container">
    <div class="tutorial-container-inner">
      <p class="p-title">SERVER ERROR!</p>
      <p>
        There was a problem <br />connecting to the database.<br /><br />Please
        check your <br />connection and refresh.
      </p>
      <button class="error-button fade-button" @click="RefreshPage">
        REFRESH
      </button>
    </div>
  </div>
</template>

<script>
import { commontools } from "@/mixins/Common";
export default {
  name: "ErrorBox",
  mixins: [commontools],
  props: {
    show: Number,
  },
  data() {
    return {};
  },
  methods: {
    RefreshPage() {
      location.reload();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.error-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, 100vh, 0px);
  -webkit-transform: translate3d(-50%, 100vh, 0px);
  opacity: 0;
  width: 300px;
  height: 405px;
  background-image: url(@/assets/panel2.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  box-sizing: border-box;
  z-index: 10;
  transition: 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  -webkit-transition: 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.error-container.active {
  opacity: 1;
  transform: translate3d(-50%, -50%, 0px);
  -webkit-transform: translate3d(-50%, -50%, 0px);
}
.error-button {
  background-color: #ec008c;
  padding: 5px 10px;
  margin: 5px 0 0;
  border-radius: 2px;
  border: none;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #000;
  width: 90px;
  box-shadow: inset -2px 2px #cc0099;
}
</style>
