<template>
  <div class="cookie-banner" v-if="isActive">
    <div class="cookie-inner">
      <h2>YOUR PRIVACY</h2>
      <p>
        We use cookies to improve your experience on our site. To find out more
        please read our
        <a href="https://ralphandco.com/docs/privacy/" target="_blank"
          >Cookie Policy</a
        >
      </p>
      <button class="share-button cookie-button" @click="acceptCookies">
        OK
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "CookieApp",
  data() {
    return {
      cookies: [],
      cookieBanner: {
        cookieTitle: "",
        cookieDesc: "",
        cookieButton: "",
      },
      isActive: true,
    };
  },
  methods: {
    showCookieBanner() {
      this.isActive = true;
    },
    acceptCookies() {
      const d = new Date();
      d.setTime(d.getTime() + 90 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = "cAccepted=true; SameSite=Lax;" + expires + ";path=/";
      this.isActive = false;
    },
    getCookie() {
      let name = "cAccepted=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.getCookie() === "true") {
        this.isActive = false;
      } else {
        this.cookieBanner.cookieTitle = "Your privacy";
        this.cookieBanner.cookieDesc =
          "We use cookies to improve your experience on our site. To find out more please read our <a href='https://ralphandco.com/docs/privacy/' target='_blank'>Cookie Policy</a>.";
        this.cookieBanner.cookieButton = "OK";
      }
    });
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #08a8a7;
  box-sizing: border-box;
  padding: 10px;
  z-index: 9999;
  font-size: 12px;
  color: #fff;
}
.cookie-inner {
  text-align: left;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
}
.cookie-inner p {
  max-width: calc(100% - 100px);
}
.cookie-inner h2 {
  margin-bottom: 0;
}
.cookie-title {
  font-size: 24px;
  text-align: left;
  line-height: 30px;
}
.cookie-text {
  text-align: left;
  line-height: 18px;
}
.cookie-button {
  position: absolute;
  right: 0px;
  top: 0px;
}
</style>
