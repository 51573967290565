<template>
  <div class="header">
    <h1>
      <img class="logo" alt="Vue logo" :src="ReturnImagePath('logo.png', '')" />
    </h1>
    <button class="tutorial-button fade-button" @click="ShowTutorial"></button>
  </div>

  <MainView ref="mainView" />

  <div class="footer">
    <button class="ralph-button fade-button" @click="ClickLink">
      <img :src="ReturnImagePath('ralph.png', '')" />
    </button>
  </div>

  <CookieApp ref="cookie" />
</template>

<script>
import MainView from "./components/MainView.vue";
import { commontools } from "./mixins/Common";
import CookieApp from "./components/CookieApp.vue";
export default {
  name: "App",
  mixins: [commontools],
  data() {
    return {};
  },
  methods: {
    ShowStats() {
      this.$refs.mainView.ShowPanel(0);
    },
    ShowTutorial() {
      this.$refs.mainView.ShowPanel(1);
    },
    ClickLink() {
      window.open("https://ralphandco.com/en/");
    },
  },
  components: {
    MainView,
    CookieApp,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #fcc100;
}
h1 {
  margin: 0;
}
p,
.p-share {
  font-family: "Inter", monospace;
  font-weight: bold;
  margin: 10px 0 10px;
  font-size: 13px;
  line-height: 17px;
}
.p-title {
  font-family: "paralucent-condensed", sans-serif;
  font-weight: 600;
  margin: 0;
  line-height: 30px !important;
  color: #ec008c;
  font-size: 24px;
  text-transform: uppercase;
}
.p-title-white {
  color: #ffffff;
}
.statbox-container .p-title {
  font-size: 32px;
}
.p-movie {
  color: black;
  text-transform: uppercase;
  font-family: "paralucent-condensed", sans-serif;
  font-weight: 600;
  margin: 0;
  font-size: 32px;
  line-height: 36px;
  min-height: 74px;
}
.p-title-fixed {
  position: absolute;
  width: 100%;
  top: 35px;
}
.p-emojis {
  font-family: "paralucent-condensed", sans-serif;
  font-weight: 600;
  margin: 0px 0 0 6px !important;
  line-height: 40px !important;
  font-size: 40px !important;
  letter-spacing: 5px;
}
.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#app {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "Inter", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222222;
  letter-spacing: 0.04em;
}
.header {
  position: absolute;
  width: 100%;
  height: 42px;
  padding: 10px 0;
}
.logo {
  width: 300px;
  height: 81px;
  margin: 10px auto 0;
}
.fade-button {
  cursor: pointer;
  transition: opacity 0.3s;
}
.fade-button:hover {
  opacity: 0.6 !important;
}
.close-button {
  background-color: transparent;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 32px;
  height: 32px;
  border: none;
  z-index: 10;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("@/assets/close.png") !important;
}
.tutorial-button {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  background-color: #00c0c0;
  border: 1px solid #000;
  background-size: 100% auto;
  background-repeat: no-repeat;
  box-shadow: inset -3px 2px #08a8a7;
  background-image: url("@/assets/tutorial.png") !important;
}
.close-button-stats {
  top: 25px;
}
.close-button-tut {
  top: 55px;
  right: 10px;
  background-image: url("@/assets/close_white.png") !important;
}
.menu-panel {
  z-index: 2;
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 414px;
  height: 100%;
  background-color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
  transform: translate3d(101%, 0px, 0px);
}
.menu-panel.active {
  transform: translate3d(0%, 0px, 0px);
}
.menu-inner button {
  font-family: "Inter", monospace;
  cursor: pointer;
  line-height: 1;
  vertical-align: baseline;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0;
}
li button {
  margin-bottom: 10px;
  background-color: transparent;
  border: none;
  max-width: 100px;
}
.ralph-button {
  border: none;
  display: inline-block;

  background-color: transparent;
}
.ralph-button img {
  height: 40px;
  width: 150px;
}
.menu-inner {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
ul {
  list-style: none;
  padding: 0;
}
.footer {
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;
  text-align: right;
  background-color: #fff;
  line-height: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.footer span {
  line-height: 40px;
}

@media screen and (max-width: 414px) {
  .menu-button.stats-button {
    right: 10px;
  }
  .menu-button.tutorial-button {
    right: 46px;
  }
  .header {
    height: 64px;
  }
  .logo {
    margin: 0 auto 0px;
    width: 240px;
    height: 64px;
  }
  .menu-button {
    width: 33px !important;
    height: 33px !important;
    top: 10px;
  }
}
</style>
